import {useEffect, useRef, useState} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {
  appSettingsStore,
  BreadcrumbModel,
  useAppSettingsStore,
} from '../../../modules/settings/core/appSettingsStore'
import {initialMessage, useMessageStore} from '../core/messagesStore'
import MessageEditorRecipients from './MessageEditorRecipients'
import MessageEditorContent from './MessageEditorContent'
import MessageEditorShortContent from './MessageEditorShortContent'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {
  timeDifference,
  formatDateDMYHI,
  formatMoney,
  removeHtmlTags,
  trimCharacters,
  pluralise,
} from '../../../../_helpers/_helpers'
import toast, {Toaster} from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {useEventsStore} from '../../../modules/events/core/eventsStore'
import {Modal} from 'react-bootstrap'
import DateInput from '../../../../_components/DateInput'
import Swal from 'sweetalert2'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import PhoneField from '../../../../_components/PhoneField'
import MessageEditorEvent from './MessageEditorEvent'

// VALIDATION SCHEMA
const messageSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  recipients: Yup.object().test(
    'recipients',
    'There are no recipients in your current selection',
    (value: any, context) => {
      if (!value) {
        return false
      }

      return value.number > 0
    }
  ),
  channels: Yup.array().required('Channels are required'),
  shortContent: Yup.string().test('shortContent', 'Short content is required', (value, context) => {
    if (context.parent?.channels.includes('sms')) {
      if (!value) return false
      const valueWithoutTags = value?.replace(/<[^>]*>/g, '')
      return !!valueWithoutTags
    }
    return true
  }),
  subject: Yup.string().test('subject', 'Subject is required', (value, context) => {
    if (!value) {
      return false
    }

    if (context.parent?.channels.includes('email')) {
      const valueWithoutTags = value?.replace(/<[^>]*>/g, '')
      return !!valueWithoutTags
    }
    return true
  }),
  content: Yup.string().test('content', 'Content is required', (value, context) => {
    if (context.parent?.channels.includes('email')) {
      if (!value) return false
      const valueWithoutTags = value?.replace(/<[^>]*>/g, '')
      return !!valueWithoutTags
    }
    return true
  }),
})

const MessageEditor = () => {
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const {
    currentMessage,
    getMessage,
    setCurrentMessage,
    unsetCurrentMessage,
    postMessage,
    patchMessage,
    sendMessage,
    sendTestMessage,
    cloneMessage,
    deleteMessage,
  } = useMessageStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {messageId} = useParams<{messageId: string}>()
  const [sendingMessage, setSendingMessage] = useState(false)
  const [preview, setPreview] = useState<'content' | 'shortContent'>()
  const {events, getEvents} = useEventsStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSendModal, setShowSendModal] = useState<boolean>(false)
  const {appSettings} = appSettingsStore()
  const [deletingMessage, setDeletingMessage] = useState<boolean>(false)
  const [cloningMessage, setCloningMessage] = useState<boolean>(false)
  const navigate = useNavigate()
  const [testPhone, setTestPhone] = useState('')
  const [testEmail, setTestEmail] = useState('')
  const [testPhoneError, setTestPhonError] = useState<string | null | undefined>(null)

  // LOAD EVENTS
  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    getEvents(selectedAccountsUsers.account.id)

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  // GET CURRENT MESSAGE
  useEffect(() => {
    if (messageId) {
      setIsLoading(true)
      getMessage(messageId)
        .then((message) => {
          setCurrentMessage(message)

          if (message.channels.includes('email')) {
            setPreview('content')
          } else if (message.channels.includes('sms')) {
            setPreview('shortContent')
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      unsetCurrentMessage()
    }

    // eslint-disable-next-line
  }, [messageId])

  // SETUP BREADCRUMBS
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Messages',
        title: 'All',
        link: '/messages',
      },
    ]

    if (currentMessage) {
      crumbs.push({
        object: 'Message',
        title: currentMessage.name,
        link: `/messages/${currentMessage.id}`,
      })
    } else {
      crumbs.push({
        object: 'message',
        title: 'Create',
        link: '/messages/create',
      })
    }

    setBreadcrumbs(crumbs)
    setCurrentPage('Messages')

    // eslint-disable-next-line
  }, [])

  // PREVIEW IFRAME
  const iframeRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (!currentMessage.id) return

    if (preview === 'content') {
      if (iframeRef.current) {
        const doc = iframeRef.current.contentDocument
        if (doc) {
          doc.open()
          doc.write(
            currentMessage.previewContent ??
              `<div style="display:flex; justify-content:center; align-items:center; width:100%; height:500px;"><p style="text-align:center; font-family: arial, sans-serif;font-size:.8rem;">A preview is not yet ready.  Please save the message to generate a preview</p></div>`
          )
          doc.close()

          // scroll to top
          containerRef.current.scrollTo({top: 110, behavior: 'smooth'})
        }
      }
    } else if (preview === 'shortContent') {
      if (iframeRef.current) {
        const doc = iframeRef.current.contentDocument
        if (doc) {
          doc.open()
          doc.write(
            currentMessage.previewShortContent ??
              `<div style="display:flex; justify-content:center; align-items:center; width:100%; height:500px;"><p style="text-align:center; font-family: arial, sans-serif;font-size:.8rem;">A preview is not yet ready.  Please save the message to generate a preview</p></div>`
          )
          doc.close()

          // scroll to top
          containerRef.current.scrollTo({top: 110, behavior: 'smooth'})
        }
      }
    }

    // eslint-disable-next-line
  }, [preview, currentMessage])

  // HANDLERS
  const handleSubmit = async (values) => {
    try {
      values.account = selectedAccountsUsers.account.id
      const payload = {...values}
      payload.recipients = JSON.stringify(values.recipients)
      setIsLoading(true)
      if (currentMessage.id) {
        await patchMessage(payload)

        toast.success('Message updated')
      } else {
        const message = await postMessage(payload)
        toast.success('Message created')

        // update current message
        setCurrentMessage(message)

        // update rule id in the url
        window.history.replaceState({}, '', `/messages/${message.id}`)
      }
    } catch (err) {
      toast.error('Error saving message')
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloneMessage = async () => {
    try {
      if (!currentMessage.id) return

      Swal.fire({
        html: 'Are you sure you want to clone this message?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            if (!currentMessage.id) return
            setCloningMessage(true)
            const clonedMessage = await cloneMessage(currentMessage.id)
            toast.success('Message cloned.  You are now viewing the cloned message')

            // update current message
            setCurrentMessage(clonedMessage)

            // update rule id in the url
            navigate(`/messages/${clonedMessage.id}`)
          } catch (err) {
            toast.error('Error cloning message')
            console.log(err)
          } finally {
            setCloningMessage(false)
          }
        }
      })
    } catch (err) {
      toast.error('Error cloning message')
      console.log(err)
    }
  }

  const handleDeleteMessage = async () => {
    try {
      if (!currentMessage.id) return

      Swal.fire({
        html: 'Are you sure you want to delete this message?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            if (!currentMessage.id) return
            setDeletingMessage(true)
            await deleteMessage(currentMessage.id)
            toast.success('Message deleted')
            navigate('/messages')
          } catch (err) {
            toast.error('Error deleting message')
            console.log(err)
          } finally {
            setDeletingMessage(false)
          }
        }
      })
    } catch (err) {
      toast.error('Error deleting message')
      console.log(err)
    }
  }

  const handleSendTestMessage = async () => {
    if (!currentMessage.id) return

    try {
      await sendTestMessage(currentMessage.id, testEmail, testPhone)
      toast.success('Test message sent')
    } catch (err) {
      toast.error('Error sending test message')
      console.log(err)
    }
  }

  const handleSendMessage = async () => {
    if (!currentMessage.id) return

    Swal.fire({
      html: 'Are you sure you want to send this message now?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (!currentMessage.id) return

          toast.success(
            'Sending messages may take long. Therefore, keep checking the messages page for a status update.',
            {
              duration: 5000,
            }
          )
          setSendingMessage(true)

          await sendMessage(selectedAccountsUsers.account.id, currentMessage.id)

          navigate('/messages')
        } catch (err) {
          toast.error('Error sending message')
          console.log(err)
        } finally {
          setSendingMessage(false)
          setShowSendModal(false)
        }
      }
    })
  }

  return (
    <>
      <Toaster />
      {isLoading && <PageLoadingSpinner />}

      {/* PAGE DETAILS */}
      <Formik
        initialValues={currentMessage || initialMessage}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={messageSchema}
      >
        {(form) => {
          return (
            <>
              {/* TOOLBAR */}
              <div className='d-flex w-100 justify-content-between'>
                {/* message status */}
                <div className='d-flex align-items-center btn btn-sm btn-outline'>
                  <span
                    className={clsx(`rounded rounded-circle p-0 me-2 w-15px h-15px`, {
                      'bg-secondary': currentMessage.status === 'draft',
                      'bg-success': currentMessage.status === 'sent',
                      'bg-danger': currentMessage.status === 'failed',
                      'bg-warning': currentMessage.status === 'pending',
                      'badge-info': currentMessage.status === 'scheduled',
                      'bg-dark': currentMessage.status === 'sending',
                    })}
                  ></span>
                  Status: &nbsp;<b>{`${currentMessage.status}`}</b>
                </div>

                <div className='d-flex align-items-center'>
                  {/* TOOLBAR */}
                  <button
                    type='button'
                    disabled={!form.isValid || currentMessage.status === 'sent'}
                    onClick={() => form.submitForm()}
                    className={clsx('btn btn-sm btn-outline btn-active-secondary me-2', {
                      'text-muted': !form.isValid || currentMessage.status === 'sent',
                      'text-dark': form.isValid && currentMessage.status !== 'sent',
                    })}
                  >
                    {'Save Message'}
                  </button>

                  {/* send button */}
                  <Tippy content='Sending options' placement='top'>
                    <button
                      type='button'
                      disabled={
                        sendingMessage ||
                        !currentMessage.id ||
                        !form.isValid ||
                        currentMessage.status === 'sent'
                      }
                      className='btn btn-sm btn-outline btn-active-secondary me-2'
                      onClick={() => setShowSendModal(true)}
                    >
                      {sendingMessage ? (
                        <span className='spinner-border spinner-border-sm me-1'></span>
                      ) : (
                        <KTIcon
                          iconName='send'
                          className={clsx('fs-5 p-0', {
                            'text-muted':
                              !currentMessage.id ||
                              !form.isValid ||
                              currentMessage.status === 'sent',
                            'text-dark':
                              currentMessage.id && form.isValid && currentMessage.status !== 'sent',
                          })}
                          iconType='outline'
                        />
                      )}
                    </button>
                  </Tippy>

                  {/* clone button */}
                  {currentMessage.id && (
                    <Tippy content='Clone message' placement='top'>
                      <button
                        type='button'
                        disabled={cloningMessage || !currentMessage.id}
                        onClick={handleCloneMessage}
                        className='btn btn-sm btn-outline btn-active-secondary me-2'
                      >
                        {cloningMessage ? (
                          <span className='spinner-border spinner-border-sm'></span>
                        ) : (
                          <KTIcon
                            iconName='copy'
                            className={clsx(
                              'fs-5 p-0',
                              {'text-muted': cloningMessage || !currentMessage.id},
                              {'text-dark': !cloningMessage && currentMessage.id}
                            )}
                            iconType='outline'
                          />
                        )}
                      </button>
                    </Tippy>
                  )}

                  {/* delete button */}
                  <Tippy content='Delete message' placement='top'>
                    <button
                      disabled={
                        deletingMessage ||
                        !currentMessage.id ||
                        currentMessage.status === 'sent' ||
                        currentMessage.status === 'sending'
                      }
                      type='button'
                      onClick={handleDeleteMessage}
                      className='btn btn-sm btn-outline btn-active-secondary'
                    >
                      {deletingMessage ? (
                        <span className='spinner-border spinner-border-sm'></span>
                      ) : (
                        <KTIcon
                          iconName='trash'
                          className={clsx(
                            'fs-5 p-0',
                            {
                              'text-muted':
                                deletingMessage ||
                                !currentMessage.id ||
                                currentMessage.status === 'sent' ||
                                currentMessage.status === 'sending',
                            },
                            {
                              'text-dark':
                                !deletingMessage &&
                                currentMessage.id &&
                                currentMessage.status !== 'sent',
                            }
                          )}
                          iconType='outline'
                        />
                      )}
                    </button>
                  </Tippy>

                  {/* send test message button */}
                </div>
              </div>

              {/* FORM */}
              <Form>
                <div className='row fv-row h-100'>
                  <div className='col-8 py-10 pe-10'>
                    {/* NAME */}
                    <div className='d-flex align-items-center justify-content-between p-5 border border-secondary rounded bg-light mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                              'bg-secondary': !form.values.name,
                              'bg-success': form.values.name,
                              'bg-danger': form.errors.name,
                            })}
                          ></div>
                          Message Name
                        </div>
                        {form.errors.name ? (
                          <ErrorMessage className='text-danger' name='name'></ErrorMessage>
                        ) : (
                          <span className='text-muted mb-2 fs-7'>
                            The message name is only visible to admins
                          </span>
                        )}
                      </div>
                      <Field
                        disabled={currentMessage.status === 'sent'}
                        name='name'
                        onBlur={() => currentMessage.id && form.submitForm()}
                        className='form-control form-lg w-300px'
                      />
                    </div>

                    {/* EVENT */}
                    <div className='d-flex align-items-center justify-content-between p-5 border border-secondary rounded bg-light mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                              'bg-secondary': !form.values.event,
                              'bg-success': form.values.event,
                              'bg-danger': form.errors.event,
                            })}
                          ></div>
                          Linked Event
                        </div>
                        {form.errors.event ? (
                          <ErrorMessage className='text-danger' name='event'></ErrorMessage>
                        ) : (
                          <>
                            {!form.values.event && (
                              <span className='text-muted mb-2 w-75 fs-7'>
                                Select the event you want to associate with this message. This is
                                great for sending invites or event related messages
                              </span>
                            )}
                            {form.values.event && (
                              <span className='text-muted mb-2 fs-7'>
                                This message is linked to{' '}
                                <b>
                                  {events.find((event) => event.id === form.values.event)?.name}
                                </b>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <MessageEditorEvent
                        disabled={currentMessage.status === 'sent'}
                        onSave={(event) => {
                          form.setFieldValue('event', event)
                          if (currentMessage.id) {
                            form.submitForm()
                          }
                        }}
                      />
                    </div>

                    {/* RECIPIENTS */}
                    <div
                      className={clsx(
                        `d-flex align-items-center justify-content-between p-5  border border-secondary bg-light rounded  mb-5`
                      )}
                    >
                      <div className='d-flex flex-column w-50'>
                        <div className='fw-bold mb-2 fs-5 align-items-center d-flex'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                              'bg-secondary':
                                !form.values.recipients || form.values.recipients.number === 0,
                              'bg-success': form.values.recipients.number > 0,
                              'bg-danger': form.errors.recipients,
                            })}
                          ></div>
                          Recipients
                        </div>
                        {form.errors.recipients ? (
                          <span className='text text-dark'>
                            There are no recipients in your current selection
                          </span>
                        ) : (
                          <span className='text-muted mb-2 fs-7'>
                            {form.values.recipients.number > 0
                              ? `Selected ${form.values.recipients.number} ${pluralise(
                                  form.values.recipients.number,
                                  'recipient'
                                )}`
                              : `Select recipients from bookings or circles`}
                          </span>
                        )}
                      </div>

                      <MessageEditorRecipients
                        disabled={currentMessage.status === 'sent'}
                        defaultRecipients={form.values.recipients as any}
                        onSave={(recipients) => form.setFieldValue('recipients', recipients)}
                      />
                    </div>

                    {/* CHANNELS */}
                    <div
                      className={clsx(
                        'd-flex align-items-center justify-content-between p-5  border border-secondary rounded bg-light mb-5'
                      )}
                    >
                      <div className='d-flex flex-column w-50'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                              'bg-secondary': form.values.channels.length === 0,
                              'bg-success': form.values.channels.length > 0,
                            })}
                          ></div>
                          Channels
                        </div>
                        <span className='text-muted mb-2 fs-7'>
                          How do you want to deliver the message
                        </span>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <label className='form-check fw-bold bg-light cursor-pointer p-2 border border-secondary rounded form-check-custom form-check-solid align-items-start mb-5 me-2'>
                          <Field
                            className='form-check-input me-3'
                            type='checkbox'
                            value='email'
                            name='channels'
                            disabled={currentMessage.status === 'sent'}
                          />
                          Email
                        </label>

                        <label className='form-check fw-bold bg-light cursor-pointer p-2 border border-secondary rounded form-check-custom form-check-solid align-items-start mb-5'>
                          <Field
                            className='form-check-input me-3'
                            type='checkbox'
                            value='sms'
                            name='channels'
                            disabled={currentMessage.status === 'sent'}
                          />
                          SMS
                        </label>
                      </div>
                    </div>

                    {/* LONG CONTENT */}
                    {form.values.channels.includes('email') && (
                      <div className='d-flex align-items-center justify-content-between p-5 border border-secondary rounded bg-light ms-10 mb-5'>
                        <div className='d-flex flex-column w-75'>
                          <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                            <div
                              className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                                'bg-secondary': !form.values.content || !form.values.subject,
                                'bg-success': form.values.content && form.values.subject,
                                'bg-danger': form.errors.content || form.errors.subject,
                              })}
                            ></div>
                            Email Content
                          </div>
                          {form.errors.content || form.errors.subject ? (
                            <div className='text-danger'>
                              <ErrorMessage className='text-danger' name='content'></ErrorMessage>{' '}
                              <ErrorMessage className='text-danger' name='subject'></ErrorMessage>
                            </div>
                          ) : (
                            <span className='text-muted mb-2 fs-7'>
                              {trimCharacters(
                                `Subject: ${removeHtmlTags(form.values.subject)}`,
                                30
                              )}

                              {trimCharacters(
                                ` | Content: ${removeHtmlTags(form.values.content)}`,
                                30
                              )}
                            </span>
                          )}
                        </div>
                        <MessageEditorContent
                          disabled={currentMessage.status === 'sent'}
                          onSave={(from, subject, content) => {
                            form.setFieldValue('from', from)
                            form.setFieldValue('subject', subject)
                            form.setFieldValue('content', content)
                            if (currentMessage.id) {
                              form.submitForm()
                            }
                          }}
                        />
                      </div>
                    )}

                    {/* SHORT CONTENT */}
                    {form.values.channels.includes('sms') && (
                      <div className='d-flex align-items-center justify-content-between p-5 border border-secondary rounded bg-light ms-10 mb-5'>
                        <div className='d-flex flex-column w-50'>
                          <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                            <div
                              className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                                'bg-secondary': !form.values.shortContent,
                                'bg-success': form.values.shortContent,
                                'bg-danger': form.errors.shortContent,
                              })}
                            ></div>
                            SMS Content
                          </div>
                          {form.errors.shortContent ? (
                            <ErrorMessage
                              className='text-danger'
                              name='shortContent'
                            ></ErrorMessage>
                          ) : (
                            <span className='text-muted mb-2 fs-7'>
                              {trimCharacters(
                                removeHtmlTags(
                                  form.values.shortContent ? form.values.shortContent : ''
                                ),
                                50
                              )}
                            </span>
                          )}
                        </div>
                        <MessageEditorShortContent
                          disabled={currentMessage.status === 'sent'}
                          onSave={(shortContent) => {
                            form.setFieldValue('shortContent', shortContent)
                            if (currentMessage.id) {
                              form.submitForm()
                            }
                          }}
                        />
                      </div>
                    )}

                    {/* SCHEDULED NOTE */}
                    {form.values.scheduledFor && form.values.status !== 'sent' && (
                      <div className='d-flex align-items-center justify-content-between p-5 border border-secondary rounded bg-light mb-5'>
                        <div className='d-flex flex-column w-50'>
                          <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                            <div
                              className={clsx('w-15px h-15px rounded rounded-circle me-2', {
                                'bg-secondary': !form.values.scheduledFor,
                                'bg-success': form.values.scheduledFor,
                              })}
                            ></div>
                            Scheduled For {formatDateDMYHI(form.values.scheduledFor.toString())}
                          </div>
                          <span className='text-muted mb-2 fs-7'>
                            Message will be sent {timeDifference(form.values.scheduledFor)}
                          </span>
                        </div>
                      </div>
                    )}

                    {/* SAVE */}
                    <div className='d-flex justify-content-end align-items-center'>
                      {/* send message */}
                      {currentMessage.id && currentMessage.status !== 'sent' && (
                        <>
                          <Modal
                            show={showSendModal}
                            centered
                            onHide={() => setShowSendModal(false)}
                          >
                            <Modal.Body className='d-flex w-100 flex-column'>
                              {/* create nav pills and tabpanes to send message now or later */}

                              <div className='d-flex justify-content-center mb-5'>
                                <ul className='nav nav-pills d-flex justify-content-center p-1 bg-light border border-secondary rounded'>
                                  <li className='nav-item'>
                                    <a
                                      className='nav-link text-gray-500 pill pill-active-dark active'
                                      data-bs-toggle='pill'
                                      href='#now'
                                    >
                                      Send Now
                                    </a>
                                  </li>
                                  <li className='nav-item'>
                                    <a
                                      className='nav-link text-gray-500 pill pill-active-dark'
                                      data-bs-toggle='pill'
                                      href='#later'
                                    >
                                      Send Later
                                    </a>
                                  </li>
                                  <li className='nav-item'>
                                    <a
                                      className='nav-link text-gray-500 pill pill-active-dark'
                                      data-bs-toggle='pill'
                                      href='#test'
                                    >
                                      Send Test
                                    </a>
                                  </li>
                                </ul>
                              </div>

                              <div className='tab-content d-flex justify-content-center'>
                                {/* NOW PANE */}
                                <div className='tab-pane active' id='now'>
                                  {/* calculate cost */}
                                  <div className='d-flex justify-content-center mb-5'>
                                    <span className='bg-light-warning p-5 rounded fs-7'>
                                      Estimate cost{' '}
                                      <b>
                                        {formatMoney(
                                          currentMessage.recipients.number *
                                            currentMessage.channels.length *
                                            appSettings.messageFee,
                                          selectedAccountsUsers.account.currency,
                                          2,
                                          false
                                        )}{' '}
                                      </b>
                                      . Fees apply to dispatched messages. If a phone or email
                                      address is incorrect, the fee will still apply. Fees are
                                      non-refundable.
                                    </span>
                                  </div>

                                  <button
                                    type='button'
                                    onClick={handleSendMessage}
                                    className='btn btn-lg w-100 btn-danger'
                                  >
                                    {
                                      sendingMessage ? (
                                        <span className='spinner-border spinner-border-sm me-1'></span>
                                      ) : (
                                        <KTIcon
                                          iconName='rocket'
                                          className='fs-2 p-0 me-2'
                                          iconType='outline'
                                        />
                                      ) /* send message */
                                    }
                                    Send Message Now
                                  </button>
                                </div>

                                {/* LATER PANE */}
                                <div className='tab-pane w-100' id='later'>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='form-label'>When do you want to send?</label>

                                    {/* DATE */}
                                    <DateInput
                                      name='scheduledFor'
                                      placeholder='Select Date'
                                      className='form-control mb-5'
                                      data-enable-time
                                    />

                                    {/* COST */}
                                    <div className='d-flex justify-content-center mb-5'>
                                      <span className='bg-light-warning p-5 rounded fs-7'>
                                        Estimate cost{' '}
                                        <b>
                                          {formatMoney(
                                            currentMessage.recipients.number *
                                              currentMessage.channels.length *
                                              appSettings.messageFee,
                                            selectedAccountsUsers.account.currency,
                                            2,
                                            false
                                          )}{' '}
                                        </b>
                                        . Fees apply to dispatched messages. If a phone or email
                                        address is incorrect, the fee will still apply. Fees are
                                        non-refundable.
                                      </span>
                                    </div>

                                    {/* SCHEDULE */}
                                    <button
                                      type='button'
                                      onClick={() => {
                                        form.setFieldValue('status', 'scheduled')
                                        form.submitForm()
                                        setShowSendModal(false)
                                      }}
                                      className='btn btn-primary'
                                    >
                                      <KTIcon
                                        iconName='time'
                                        iconType='outline'
                                        className='fs-2 p-0 me-2'
                                      />
                                      Schedule
                                    </button>
                                  </div>
                                </div>

                                {/* TEST PANE */}
                                <div className='tab-pane w-100' id='test'>
                                  <div className='d-flex flex-column w-100'>
                                    <div className='d-flex flex-column mb-5'>
                                      <label className='form-label'>Email</label>
                                      <Field
                                        onChange={(e) => setTestEmail(e.target.value)}
                                        value={testEmail}
                                        name='testEmail'
                                        placeholder='Email'
                                        className='form-control'
                                      />
                                    </div>
                                    <div className='d-flex flex-column mb-10'>
                                      <label className='form-label'>Phone</label>
                                      <PhoneField
                                        onChange={(e) => setTestPhone(e)}
                                        value={testPhone}
                                        onError={(err) => setTestPhonError(err)}
                                      />
                                      {testPhoneError && (
                                        <span className='text-danger'>{testPhoneError}</span>
                                      )}
                                    </div>

                                    <button
                                      type='button'
                                      onClick={() => {
                                        handleSendTestMessage()
                                      }}
                                      className='btn btn-outline btn-active-dark'
                                    >
                                      <KTIcon
                                        iconName='send'
                                        iconType='outline'
                                        className='fs-2 p-0 me-2'
                                      />
                                      Send Test
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    ref={containerRef}
                    className={clsx(
                      'col-4 mt-10 p-5 bg-light border border-secondary rounded position-relative',
                      {'d-none': !currentMessage.id}
                    )}
                    style={{overflowY: 'auto', overflowX: 'unset'}}
                  >
                    {/* switch preview with pills */}
                    <div className='d-flex justify-content-center position-sticky top-0 p-5'>
                      <div className='glass-pane p-3 border border-solid rounded'>
                        <button
                          type='button'
                          disabled={!form.values.channels.includes('email')}
                          className='btn btn-sm btn-outline btn-active-dark me-2'
                          style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                          onClick={() => {
                            setPreview('content')
                          }}
                        >
                          Email
                        </button>
                        <button
                          type='button'
                          disabled={!form.values.channels.includes('sms')}
                          className='btn btn-sm btn-outline btn-active-dark me-2'
                          style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                          onClick={() => {
                            setPreview('shortContent')
                          }}
                        >
                          SMS
                        </button>
                      </div>
                    </div>

                    {/* PREVIEW: INJECT HTML */}
                    <iframe
                      ref={iframeRef}
                      title='Email Preview'
                      style={{
                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        marginTop: '0px',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                    />
                  </div>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default MessageEditor
