import React, {FC, useEffect} from 'react'

import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useEventsStore} from '../../events/core/eventsStore'
import {initialPatron, PatronModel, usePatronStore} from '../../patrons/core/patronStore'
import toast, {Toaster} from 'react-hot-toast'
import {CreatePatronType} from '../../patrons/core/patronApi'
import PhoneField from '../../../../_components/PhoneField'
import SubmitButton from '../../../../_components/SubmitButton'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  patron?: PatronModel
  onLoggedIn?: (patron: PatronModel) => void
  onLogout?: () => void
}

const PatronBookingTicketAuth: FC<Props> = ({patron, onLoggedIn, onLogout}) => {
  const [createNewPatron, setCreateNewPatron] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const {publicEvent} = useEventsStore()
  const {registerPatron, searchPatron} = usePatronStore()

  const [buttonLabel, setButtonLabel] = React.useState<string>('Next')
  const [signInMode, setSignInMode] = React.useState<string>('phone')
  const [currentPatron, setCurrentPatron] = React.useState<PatronModel>(patron || initialPatron)

  useEffect(() => {
    if (patron) {
      setCurrentPatron(patron)
      setButtonLabel('Next')
      setCreateNewPatron(false)
    } else {
      setCurrentPatron(initialPatron)
      setButtonLabel('Next')
      setCreateNewPatron(false)
    }

    // eslint-disable-next-line
  }, [patron])

  const handleSubmit = async (values: PatronModel, actions) => {
    setIsSubmitting(true)

    if (!currentPatron.id && !createNewPatron) {
      // SEARCH PATRON
      try {
        const searchQuery = signInMode === 'phone' ? {phone: values.phone} : {email: values.email}
        const r: PatronModel = await searchPatron(searchQuery)

        setCurrentPatron(r)

        // tell listeners
        onLoggedIn && onLoggedIn(r)
      } catch (err: any) {
        if (err.response.status === 404) {
          setCreateNewPatron(true)
          setButtonLabel('Create Patron')
        } else {
          toast.error(err.response.data.message)
        }
        setIsSubmitting(false)
      }
    } else if (createNewPatron === true) {
      // CREATE NEW PATRON
      try {
        if (publicEvent.id === null) throw new Error('Event not found')

        const payload: CreatePatronType = {
          name: values.name,
          phone: values.phone,
          email: values.email,
          account: publicEvent.account.id,
        }
        const r = await registerPatron(payload)
        setCreateNewPatron(false)
        onLoggedIn && onLoggedIn(r)
        setButtonLabel('Next')
      } catch (err: any) {
        toast.error(err.response.data.message)
      }
    }

    setIsSubmitting(false)
  }

  const handleCancelClick = async (form) => {
    if (currentPatron.id) {
      setCurrentPatron({...initialPatron})
      setCreateNewPatron(false)
    } else if (createNewPatron) {
      setCreateNewPatron(false)
    }

    // tell listeners
    onLogout && onLogout()
  }

  return (
    <>
      <Formik initialValues={currentPatron} enableReinitialize onSubmit={handleSubmit}>
        {(form) => {
          return (
            <Form>
              <Toaster position='top-center' />

              <div className='d-flex flex-column w-100'>
                {currentPatron.id ? (
                  <div className='mb-5'>
                    <div className='alert-text fs-5'>
                      You are about to allocate this ticket to{' '}
                      <span className='fw-bold'>{currentPatron.name}</span>.
                      <button
                        onClick={handleCancelClick}
                        className='fw-bold text-primary btn btn-link d-inline ms-3'
                      >
                        Use another patron
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className=''>
                    {/* search patron with phone or email */}
                    {/* nav links */}
                    <div className='fv-row d-flex flex-column w-100'>
                      <div className='d-flex fw-bold rounded bg-light py-3 mb-10 d-flex w-100 justify-content-center align-items-center'>
                        <span className='me-5'>Search With</span>
                        <ul className='nav nav-pills justify-content-center'>
                          <li className='nav-item'>
                            <a
                              href='#tab_phone'
                              onClick={() => setSignInMode('phone')}
                              data-bs-toggle='tab'
                              className='nav-link active'
                            >
                              Mobile
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              href='#tab_email'
                              onClick={() => setSignInMode('email')}
                              data-bs-toggle='tab'
                              className='nav-link'
                            >
                              Email
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* tab content */}
                    <div className='tab-content'>
                      <div id='tab_phone' className='tab-pane fade show active'>
                        <div className='mb-10 fv-row d-flex flex-column text-start'>
                          <label className='form-label mb-3' aria-required>
                            Phone Number (required)
                          </label>
                          <PhoneField name='phone' />
                          <div className='text-danger mt-2'>{form.errors.phone}</div>
                        </div>
                      </div>

                      <div id='tab_email' className='tab-pane fade'>
                        <div className='mb-10 fv-row text-start'>
                          <label className='form-label mb-3' aria-required>
                            Email Address (required)
                          </label>
                          <Field name='email' className='form-control' />
                          <div className='text-danger mt-2'>{form.errors.email}</div>
                        </div>
                      </div>
                    </div>
                    {createNewPatron && (
                      <div className='mb-10 fv-row'>
                        <label className='form-label mb-3'>Your Name (required)</label>

                        <Field type='text' className='form-control form-control-lg ' name='name' />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='name' />
                        </div>
                      </div>
                    )}
                    <div className='mb-10 fv-row '>
                      <SubmitButton
                        label={buttonLabel}
                        isSubmitting={isSubmitting}
                        className='btn btn-primary w-100 mb-5'
                      />
                      {createNewPatron && (
                        <button
                          onClick={() => handleCancelClick(form)}
                          type='button'
                          className='btn btn-light-danger w-100'
                        >
                          <KTIcon iconName='angle-right' className='me-2' />
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {PatronBookingTicketAuth}
