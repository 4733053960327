/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useEventsStore} from './core/eventsStore'
import {useNavigate, useParams} from 'react-router-dom'
import {eventDateLabel} from '../../../_helpers/_templateHelpers'
import {formatDateDMYHI, formatMoney, formatPhoneNumber} from '../../../_helpers/_helpers'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import MapViewer from '../../../_components/MapViewer'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {Helmet} from 'react-helmet'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import {
  GA_EVENT_EVENT_VIEW_PAGE,
  initializeGA,
  trackEvent,
  trackPageView,
} from '../../../_helpers/_analytics'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'
import {useMessageStore} from '../../pages/messages/core/messagesStore'

const EventPublicPage: FC = () => {
  const {getPublicEvent, publicEvent} = useEventsStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {eventId} = useParams<{eventId: string}>()

  const {appSettings} = useAppSettingsStore()
  const navigate = useNavigate()
  const [logo, setLogo] = useState('/media/logos/default-small.png')
  const [hasLogo, setHasLogo] = useState(false)
  const [showYoutubeModal, setShowYoutubeModal] = useState(false)
  const [topImgHeight, setTopImgHeight] = useState(0)
  const [imgElement, setImgElement] = useState<HTMLImageElement | null>(null)
  const artworkRef = useRef<HTMLDivElement>(null) // Create ref for .artwork div

  const [ogData, setOgData] = useState({
    title: '',
    description: '',
    url: '',
    image: '',
    siteName: '',
    type: 'event',
    street_address: '',
    start_time: '',
    end_time: '',
  })

  const {getMessage} = useMessageStore()

  // GA PAGE VIEW
  useEffect(() => {
    initializeGA()
    trackPageView(window.location.pathname)
  }, [])

  // OBSERVE IMAGE HEIGHT FOR FIXED POSITION
  useEffect(() => {
    if (!imgElement) return

    const handleLoadOrResize = () => {
      const imgHeight = imgElement.clientHeight
      setTopImgHeight(imgHeight)
    }

    // Get height immediately if image already loaded
    if (imgElement.complete) handleLoadOrResize()

    // Listen for future load/resize events
    imgElement.addEventListener('load', handleLoadOrResize)
    const resizeObserver = new ResizeObserver(handleLoadOrResize)
    resizeObserver.observe(imgElement)

    return () => {
      imgElement.removeEventListener('load', handleLoadOrResize)
      resizeObserver.unobserve(imgElement)
    }
  }, [imgElement]) // Re-run when img element changes

  // CHECK IF THIS IS FROM MESSAGE
  useEffect(() => {
    // get value of m in query string
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const messageId = urlParams.get('m')

    if (messageId) {
      getMessage(messageId)
    }

    // eslint-disable-next-line
  }, [])

  useState(() => {
    // get event id
    setIsLoading(true)
    // if current event is not set
    if (eventId && eventId !== 'null') {
      getPublicEvent(eventId)
        .then((event) => {
          // set og data
          const og = {
            title: `skybookings.com | ${event.name}`,
            description: event.excerpt,
            url: `${appSettings.baseUrl}/events/${event.id}`,
            image: event.artwork,
            siteName: appSettings.name,
            type: 'event',
            street_address: '',
            start_time: event.startDate,
            end_time: event.endDate,
          }

          if (event.location) og.street_address = event.location
          setOgData(og)

          // RECORD GA EVENT
          if (event.id) {
            trackEvent(GA_EVENT_EVENT_VIEW_PAGE, {
              eventId: event.id,
              accountId: event.account.id,
            })
          }

          // set logo
          if (event.logo) {
            setLogo(event.logo)
            setHasLogo(true)
          }
        })

        .finally(() => {
          setIsLoading(false)
        })
    }

    // show header when scroll position is top
    const scrollHandler = () => {
      const header = document.querySelector('.header')

      if (window.scrollY > 0) {
        header?.classList.add('d-block')
        header?.classList.remove('d-none')
      } else {
        header?.classList.add('d-none')
        header?.classList.remove('d-block')
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>{ogData.title}</title>
            <meta name='description' content={ogData.description} />
            <link rel='canonical' href={ogData.url} />
            <meta property='og:title' content={ogData.title} />
            <meta property='og:description' content={ogData.description} />
            <meta property='og:url' content={ogData.url} />
            <meta property='og:image' content={ogData.image} />
            <meta property='og:site_name' content={ogData.siteName} />
            <meta property='og:street_address' content={ogData.street_address} />
            <meta property='og:type' content={ogData.type} />
            <meta property='og:start_time' content={ogData.start_time} />
            <meta property='og:end_time' content={ogData.end_time} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={ogData.title} />
            <meta name='twitter:description' content={ogData.description} />
            <meta name='twitter:image' content={ogData.image} />
          </Helmet>

          {/* BANNER */}
          <div className='d-flex artwork hover-overlay'>
            {!isMobileDevice() ? (
              <div className='position-relative ' ref={artworkRef}>
                {publicEvent.banner && (
                  <img
                    ref={(el) => setImgElement(el)}
                    src={publicEvent.banner}
                    alt={publicEvent.name}
                    className='w-100 position-fixed'
                  />
                )}
                {publicEvent.organiserYoutube && (
                  <div className='overlay'>
                    <button
                      onClick={() => setShowYoutubeModal(true)}
                      className='btn rounded btn-icon rounded-circle btn-sm fw-bolder position-absolute top-50 start-50 translate-middle'
                    >
                      <KTIcon
                        iconName='to-right'
                        iconType='solid'
                        className='me-2 text-hover-light fs-7x'
                      />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className='position-relative'>
                <img
                  ref={(el) => setImgElement(el)}
                  src={publicEvent.artwork}
                  alt={publicEvent.name}
                  className='w-100 position-fixed'
                />
                {
                  publicEvent.organiserYoutube && (
                    <div className='overlay'>
                      <button
                        onClick={() => setShowYoutubeModal(true)}
                        className='btn rounded btn-icon rounded-circle btn-sm fw-bolder position-absolute top-50 start-50 translate-middle'
                      >
                        <KTIcon
                          iconName='to-right'
                          iconType='solid'
                          className='me-2 text-hover-light fs-7x'
                        />
                      </button>
                    </div>
                  ) /* end: overlay */
                }
              </div>
            )}
          </div>

          {/* MENU HEADER */}
          <div
            className={clsx(`header p-5 shadow-sm d-none`, {
              'fixed-bottom': isMobileDevice(),
              'fixed-top': !isMobileDevice(),
            })}
            style={{
              backgroundColor: `rgba(255,255,255, 0.5)`,
              backdropFilter: 'blur(10px)',
              zIndex: 100,
            }}
          >
            <div className='container'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column justify-content-center mt-1'>
                    <h1 className='fs-7 fs-lg-3 mb-1 fw-bolder text-dark pb-0 title'>
                      {publicEvent.name}
                    </h1>

                    {publicEvent.sellTickets && (
                      <div className='fw-semibold mb-1 fs-7'>
                        Available from{' '}
                        {formatMoney(
                          publicEvent.stats.minTicketPrice || 0,
                          publicEvent.account.currency,
                          0
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    onClick={() => navigate('booking')}
                    disabled={publicEvent.canBook.canBook === false}
                    className='btn btn-danger btn-sm text-nowrap fw-bolder'
                    style={{borderRadius: '25px'}}
                  >
                    {publicEvent.canBook.canBook === false
                      ? publicEvent.canBook.code === 'eventFull'
                        ? 'Sold Out'
                        : 'Not Available'
                      : publicEvent.buttonLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* BODY */}
          <div
            className='d-flex flex-column align-items-center position-relative'
            style={{
              backgroundColor: `rgba(255,255,255, 0.8)`,
              backdropFilter: 'blur(20px)',
              marginTop: `${publicEvent.banner ? topImgHeight - 25 : 5}px`,
            }}
          >
            {/* 2ND BANNER  */}
            {/* <div
              className='d-flex position-absolute'
              style={{
                backgroundImage: `url(${
                  !isMobileDevice() && publicEvent.banner ? publicEvent.banner : publicEvent.artwork
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                rotate: '180deg',
                top: 0,
                left: 0,
                width: '100%',
                height: '200px',
                zIndex: 1,
              }}
            ></div> */}
            {/* <div
              className='position-absolute'
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '200px',
                zIndex: 2,
                backdropFilter: 'blur(20px)',
              }}
            ></div>
            <div
              className='position-absolute'
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '200px',
                zIndex: 3,
                background: `linear-gradient(to bottom, rgba(255,255,255, 0.5), rgba(255,255,255, 0.8))`,
              }}
            ></div> */}

            {/* content */}
            <div
              className='d-flex container row'
              style={{
                zIndex: 4,
              }}
            >
              <div className='col col-md-8 py-md-20 p-5 p-lg-10'>
                {/* headline */}
                <div className='d-flex align-items-center my-5'>
                  {hasLogo && (
                    <div className='overflow-hidden p-2 w-75px w-lg-100px flex-shrink-0 d-flex me-3 me-lg-5'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl(logo)}
                        className='app-sidebar-logo-default w-100 h-auto rounded-circle rounded'
                      />
                    </div>
                  )}
                  <div className='d-flex flex-column'>
                    <h1 className='display-7 display-lg-6 fw-bolder mb-0 '>{publicEvent.name}</h1>
                    <div className='d-flex align-items-center my-2 fs-5'>
                      <span className='fw-semibold'>By {publicEvent.organiserName}</span>
                      <KTIcon
                        iconName='verify'
                        iconType='solid'
                        className='ms-2 fs-3 text-primary'
                      />
                    </div>
                  </div>
                </div>

                {/* tickets available from */}
                <div className='d-flex overflow-x-auto'>
                  {/* date */}
                  <div
                    className='rounded p-5 fs-5 me-4 mb-3 d-flex d-block-inline'
                    style={{
                      backgroundColor: `rgba(0,0,0, 0.05)`,
                    }}
                  >
                    <div className='d-flex flex-column fw-bold'>
                      <div className='d-flex align-items-center'>
                        <KTIcon
                          iconName='calendar'
                          iconType='solid'
                          className='me-2 fs-7 text-dark'
                        />
                        <span
                          className='me-2 w-100 text-nowrap fs-5'
                          dangerouslySetInnerHTML={{
                            __html: eventDateLabel(publicEvent.startDate, publicEvent.endDate),
                          }}
                        />
                      </div>
                      <span className='fw-normal fs-6'>
                        {formatDateDMYHI(publicEvent.startDate)}
                      </span>
                    </div>
                  </div>

                  {/* location */}
                  <div
                    className='fs-5 p-5 rounded me-4 mb-3 d-flex '
                    style={{
                      backgroundColor: `rgba(0,0,0, 0.05)`,
                    }}
                  >
                    <div className='d-flex flex-column'>
                      <span className='fw-bold text-nowrap'>
                        <KTIcon
                          iconName='geolocation'
                          iconType='solid'
                          className='me-2 fs-7 text-nowrap text-dark'
                        />
                        Event Location:
                      </span>
                      <span className='fw-normal text-nowrap'>{publicEvent.location}</span>
                    </div>
                  </div>

                  {/* contact */}

                  <div
                    className='fs-5 p-5 rounded me-4 mb-3 d-flex '
                    style={{
                      backgroundColor: `rgba(0,0,0, 0.05)`,
                    }}
                  >
                    <div className='d-flex flex-column'>
                      <span className='fw-bold '>
                        <KTIcon
                          iconName='phone'
                          iconType='solid'
                          className='me-2 fs-7 text-nowrap text-dark'
                        />
                        Contact:
                      </span>
                      <a
                        target='_blank'
                        rel='noreferrer noopener'
                        href={`https://wa.me/${
                          publicEvent.organiserWhatsapp || appSettings.sbPhone
                        }`}
                        className='fw-normal text-nowrap text-dark'
                      >
                        {formatPhoneNumber(publicEvent.organiserWhatsapp || appSettings.sbPhone)}
                      </a>
                    </div>
                  </div>

                  {/* website */}
                  {publicEvent.organiserWebsite && (
                    <div
                      className='fs-5 p-5 rounded me-4 mb-3 d-flex '
                      style={{
                        backgroundColor: `rgba(0,0,0, 0.05)`,
                      }}
                    >
                      <div className='d-flex flex-column'>
                        <span className='fw-bold '>
                          <KTIcon
                            iconName='click'
                            iconType='solid'
                            className='me-2 fs-7 text-nowrap text-dark'
                          />
                          Website:
                        </span>
                        <a
                          target='_blank'
                          rel='noreferrer noopener'
                          href={publicEvent.organiserWebsite}
                          className='fw-normal text-nowrap text-dark'
                        >
                          {publicEvent.organiserWebsite.replace(/(^\w+:|^)\/\//, '')}
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {/* Exceprt and availability */}
                <div className='d-flex flex-column event-excerpt my-3'>
                  <p
                    className='fs-5 fw-semibold p-5 rounded'
                    style={{
                      backgroundColor: `rgba(0,0,0, 0.05)`,
                    }}
                  >
                    {publicEvent.excerpt}
                  </p>
                </div>

                <div className='d-flex flex-column event-content'>
                  {/* begin:  map */}
                  {publicEvent.location && (
                    <div className='map rounded mb-10'>
                      <MapViewer zoom={12} location={publicEvent.location} />
                    </div>
                  )}

                  {/* main content */}
                  <div
                    className='fs-4 event-content p-5 rounded'
                    style={{
                      backgroundColor: `rgba(0,0,0, 0.05)`,
                    }}
                    dangerouslySetInnerHTML={{__html: publicEvent.content}}
                  ></div>
                </div>

                {/* description  */}
              </div>

              <div className='col hover-overlay col-md-4 d-flex flex-column d-none d-md-block p-10'>
                {publicEvent.artwork && (
                  <div className='mb-5 position-relative'>
                    <img
                      src={publicEvent.artwork}
                      alt={publicEvent.name}
                      className='img-fluid rounded'
                    />
                    {publicEvent.organiserYoutube && (
                      <div className='overlay'>
                        <button
                          onClick={() => setShowYoutubeModal(true)}
                          className='btn rounded btn-icon rounded-circle btn-sm fw-bolder position-absolute top-50 start-50 translate-middle'
                        >
                          <KTIcon
                            iconName='to-right'
                            iconType='solid'
                            className='me-2 text-hover-light fs-7x'
                          />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div className='d-flex flex-column'>
                  {/* booking button */}
                  <button
                    onClick={() => navigate('booking')}
                    disabled={publicEvent.canBook.canBook === false}
                    className='btn btn-danger btn-lg fw-bolder w-100 mb-2'
                  >
                    {publicEvent.canBook.canBook === false
                      ? publicEvent.canBook.code === 'eventFull'
                        ? 'Sold Out'
                        : 'Not Available'
                      : publicEvent.buttonLabel}
                  </button>

                  {publicEvent.sellTickets && (
                    <div className=' text-muted text-center mb-5 fs-7'>
                      Available from{' '}
                      {formatMoney(
                        publicEvent.stats.minTicketPrice || 0,
                        publicEvent.account.currency,
                        0
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* youtube modal */}
          <Modal
            show={showYoutubeModal}
            onHide={() => setShowYoutubeModal(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Body className='p-2'>
              <ReactPlayer
                url={publicEvent.organiserYoutube}
                controls
                width='100%'
                height={isMobileDevice() ? '206px' : '425px'}
              />
            </Modal.Body>
          </Modal>

          {/* end::Row */}
        </>
      )}
    </>
  )
}

export {EventPublicPage}
